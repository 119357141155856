import Model from '~/models/Model';

export default class Profile extends Model {
  resource() {
    return 'v1/organization-profiles';
  }

  patch() {
    // User has not updated the logo with a new file.
    /* @ts-ignore */
    if (!(this.logo instanceof File)) {
      /* @ts-ignore */
      delete this.logo;
    }

    // https://github.com/laravel/framework/issues/13457
    // @ts-ignore
    return this.config({ headers: { 'X-HTTP-METHOD-OVERRIDE': 'PATCH' }, method: 'POST' }).save();
  }
}
