<template>
  <profiles-index :filters="filters" :fields="fields" :organization="organization" />
</template>

<script>
import Vue from 'vue';
import ProfilesIndex from '~/pages/shared/profiles/ProfilesIndex';

export default Vue.extend({

  components: { ProfilesIndex },

  middleware: 'tenantmember',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    fields: ['basic', 'created_at', 'actions'],
  }),
});
</script>
