import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import Vue from 'vue';
import availableFilters from './availableFilters';
import ProfileFilterCollection from './ProfileFilterCollection';
import { useFetchResources, props as fetchResourcesProps } from '~/composables/useFetchResources';
import EmptyState from '~/components/EmptyState';
import BasicOrganizationInfoCell from '~/components/Cells/BasicOrganizationInfoCell';
import DateCell from '~/components/Cells/DateCell';
export default Vue.extend({
  components: {
    EmptyState: EmptyState,
    BasicOrganizationInfoCell: BasicOrganizationInfoCell,
    DateCell: DateCell,
    ProfileFilterCollection: ProfileFilterCollection
  },
  props: _objectSpread(_objectSpread({}, fetchResourcesProps()), {}, {
    query: {
      type: Function,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    pageSize: {
      type: Number,
      required: false,
      default: 50
    }
  }),
  setup: function setup(props) {
    var _useFetchResources = useFetchResources(props),
      fetchResources = _useFetchResources.fetchResources,
      pagination = _useFetchResources.pagination,
      resources = _useFetchResources.resources,
      loading = _useFetchResources.loading;
    return {
      fetchResources: fetchResources,
      pagination: pagination,
      resources: resources,
      loading: loading
    };
  },
  computed: {
    enabledFields: function enabledFields() {
      var _this = this;
      return this.getFields().filter(function (field) {
        return _this.fields.includes(field.key);
      });
    },
    enabledFilters: function enabledFilters() {
      var _this2 = this;
      return availableFilters.filter(function (filter) {
        return _this2.filters.includes(filter.key);
      }).map(function (_ref) {
        var key = _ref.key,
          type = _ref.type;
        return {
          key: key,
          type: type
        };
      });
    }
  },
  methods: {
    getFields: function getFields() {
      return [{
        key: 'basic',
        label: this.$t('fields.name')
      }, {
        key: 'organization',
        label: this.$t('fields.organization')
      }, {
        key: 'created_at',
        label: this.$t('fields.created_at'),
        sortable: true
      }, {
        key: 'actions',
        label: '',
        contentAlign: 'right',
        noWrap: true
      }];
    }
  }
});