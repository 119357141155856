<template>
  <div class="ar-basic-organization-info-cell">
    <img :src="logoUrl" class="ar-basic-organization-info-cell__logo">
    <span class="ar-basic-organization-info-cell__name">{{ name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    logoUrl: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.ar-basic-organization-info-cell {
  display: flex;
  align-items: center;

  &__logo {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &__name {
    font-weight: bold;
  }
}
</style>
