<template>
  <t-page>
    <template #header>
      <div class="heading">
        <div>
          <h3 slot="header">
            {{ $t('fields.profiles') }}
          </h3>
          <p v-if="showTagline" v-html="$t('pages.profile.lead')" />
        </div>
        <NuxtLink v-if="canCreate" :to="{ name: $RouteEnum.PROFILES.CREATE }">
          <t-button>{{ $t('pages.profile.actions.create') }}</t-button>
        </NuxtLink>
      </div>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import UserTypeEnum from '~/enums/UserTypeEnum';
import Profile from '~/models/Profile';
import IndexTable from '~/pages/shared/profiles/partials/IndexTable';

export default Vue.extend({

  components: { IndexTable },
  props: {
    organization: {
      type: Object,
      required: false,
      default: () => undefined,
    },

    filters: {
      type: Array,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    query: null,
  }),

  fetch() {
    const query = new Profile();

    if (this.organization?.id) {
      query.where('organization_id', this.organization.id);
    }

    this.query = () => query.include(this.queryIncludes);
  },

  computed: {
    isEmployee() {
      return this.$auth.me.user.type === UserTypeEnum.EMPLOYEE;
    },

    showTagline() {
      return this.isEmployee;
    },

    queryIncludes() {
      return this.isEmployee ? '' : 'organization';
    },

    canCreate() {
      return this.$ability.can('create', 'Profile');
    },
  },
});
</script>
<style lang="scss" scoped>
.heading {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
</style>
