import { filterTypes } from '~/enums/FilterTypes';
import { OrganizationFilter } from '~/components/Filters';

export default [
  {
    key: 'organization_id',
    component: OrganizationFilter,
    type: filterTypes.ARRAY,
  },
];
